
import './App.css';
import Header from './Header/Header'
import Free from './Free/Free'
import Learn from './Learn/Learn'
import Certificate from './Certificate/Certificate'
import About from './About/About';
import ConnectUs from './ConnectUs/ConnectUs';
import Book from './Book/Book';
import { useRoutes } from 'react-router-dom'
import First from './pages/first';
function App() {
  const routes = useRoutes([
    {
      path:'', 
      element:<>
          <div className="App">
            <Header />
            <Free />
            <Learn />
            <Certificate />
            <About />
            <ConnectUs />
            <p className='footer'> © 2022 IFES Armenia: Բոլոր իրավունքները պաշտպանված են</p>
          </div>
        </>
    },
    {
      path:'book', 
      element:<Book/>, 
      children:[
        {path:'', 
         element:<First/>
        },
      ]
    }
  ])
  return routes
}

export default App;
