import emailjs from "emailjs-com";
import React from 'react';
import "./ConnectUs.css"

export default function EmailJs() {

    function sendEmail(e) {
        e.preventDefault();

    emailjs.sendForm('service_ryraejh', 'template_e41xmc6', e.target, 'S5GBQ6OmXud0f42-g')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset()
    }

    return(
        <div>
        <p className="contact_text">Contact Us</p>
            <form onSubmit={sendEmail}>
                    <div>
                        <div >
                            <input type="text" className="inp" placeholder="Անուն" name="name"/>
                        </div>
                        <div >
                            <input type="email" className="inp" placeholder="Էլ. հասցե" name="email"/>
                        </div>
                        
                        <div >
                            <textarea className="text_area" id="" cols="48" rows="8" placeholder="Նամակ" name="message"></textarea>
                        </div>
                        <div >
                            <input type="submit" className="btn" value="Send Message"></input>
                        </div>
                    </div>
                </form>
            
        </div>
    )
}