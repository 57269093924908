import './Free.css';
import facebook from '../img/facebook.png'
import linkedin from '../img/linkedin.png'
import Group135 from '../img/Group135.png'
import ifesnewlogo from '../img/ifesnewlogo.png'
import usaidlogo from '../img/USAID.png'
import hpthlogo from '../img/hpth.png'
import itlogo from '../img/it.png'


function Free() {
  return (
    <div className="Free">
        <div className='left'>
            <p className='name'>Ներգրավված <span className='citizen'>քաղաքացի</span></p>
            <p className='modul'>Քաղաքացիական կրթության նորարարական մոդուլ</p>
            
            <div className='for_logos'>
                <img id='newLogo1' src={usaidlogo} alt="" />
                <img className='newLogo' src={hpthlogo} alt="" />
                <img className='itLogo' src={itlogo} alt="" />
                <img className='newLogo' src={ifesnewlogo} alt="" />
            </div>
            
        </div>
        
    </div>
  );
}

export default Free;
