import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import {Outlet, Link} from 'react-router-dom';
import styles from "./Book.module.css";
const drawerWidth = 320;

function Book(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  

  const drawer = (
    <div>
      <ul className={styles.accordionBlock}>
          <li>
            <a href={'/book#text_1'}>ԲԱԺԻՆ 1</a>
            <ul>
              <li><a href="#text_1">1.1 ԺՈՂՈՎՐԴԱՎԱՐԱԿԱՆ ԿԱՌԱՎԱՐՄԱՆ ՓԻԼԻՍՈՓԱՅԱԿԱՆ ՀԻՄՔԵՐԸ</a></li>
              <li><a href="#text_2">1.2 ԺՈՂՈՎՐԴԱՎԱՐԱԿԱՆ ԿԱՌԱՎԱՐՄԱՆ ՓԻԼԻՍՈՓԱՅԱԿԱՆ ՀԻՄՔԵՐԸ</a></li>
              <li><a href="#text_3">1.3 ՀԱՍԱՐԱԿՈՒԹՅԱՆ ԵՎ ԱԶԳԱՅԻՆ ՊԵՏՈՒԹՅԱՆ ՁԵՎԱՎՈՐՈՒՄ</a></li>  
              <li><a href="#text_4">1.4 Ժողովրդավարության սկզբունքներ</a></li>
              <li><a href="#text_5">1.5 ԱԶԱՏԱԿԱՆ ԺՈՂՈՎՐԴԱՎԱՐՈՒԹՅԱՆ ՍԿԶԲՈՒՆՔՆԵՐԸ</a></li>
            </ul>
          </li>
        <br/>
          <li>
            <a href={'/book#bajin_2'}>ԲԱԺԻՆ 2</a>
            <ul>
              <li><a href="#text_2.1">2.1 Մարդու իրավունքների էվոլյուցիան Հայաստանում</a></li>
              <li><a href="#text_2.2">2.2 Մարդու իրավունքների վերաբերյալ միջազգային կոնվենցիաներ և պայմանագրեր</a></li>
              <li><a href="#text_2.3">2.3 ՄԱՐԴՈՒ ԻՐԱՎՈՒՆՔՆԵՐԻ ՊԱՇՏՊԱՆՈՒԹՅԱՆ ՄԵԽԱՆԻԶՄՆԵՐ</a></li>  
              <li><a href="#text_2.4">2.4 Մարդու իրավունքների կիրառումն ու մարտահրավերները Հայաստանում</a></li>
              <li><a href="#text_2.5">2.5 ՄԱՐԴՈՒ ԻՐԱՎՈՒՆՔՆԵՐԸ</a></li>
            </ul>
          </li>
         <br/>
         <li>
            <a href={'/book#bajin_3'}>ԲԱԺԻՆ 3</a>
            <ul>
              <li><a href="#text_3.1">3.1 ՍԱՀՄԱՆԱԴՐՈՒԹՅՈՒՆՆԵՐԸ ԵՎ ՊԵՏՈՒԹՅՈՒՆԸ</a></li>
              <li><a href="#text_3.2">3.2 ԿԱՌԱՎԱՐՄԱՆ ԺՈՂՈՎՐԴԱՎԱՐԱԿԱՆ ՄԵԽԱՆԻԶՄՆԵՐԸ</a></li>
              <li><a href="#text_3.3">3.3 Սահմանադրականության պատմական զարգացումը Հայաստանում</a></li>  
              <li><a href="#text_3.4">3.4 Հայաստանի պետական իշխանությունը և կառավարման համակարգը. վարչական բաժանումը Հայաստանում</a></li>
              <li><a href="#text_3.5">3.5 Ընտրություններ. ընտրությունների դերն ու նշանակությունը ժողովրդավարական հասարակություններում</a></li>
              <li><a href="#text_3.6">3.6 ԽՈՐՀՐԴԱՐԱՆԱԿԱՆ ՔՎՈՏԱՆԵՐ</a></li>
              <li><a href="#text_3.7">3.7 ԿՈՒՍԱԿՑՈՒԹՅՈՒՆՆԵՐ ԵՎ ԿՈՒՍԱԿՑԱԿԱՆ ՀԱՄԱԿԱՐԳԵՐ</a></li>
              <li><a href="#text_3.8">3.8 ԻՆՉ Է ԿՈՌՈՒՊՑԻԱՆ</a></li>
            </ul>
          </li>

          <li>
            <a href={'/book#bajin_4'}>ԲԱԺԻՆ 4</a>
            <ul>
              <li><a href="#text_4.1">4.1 Քաղ․ հասարակություն և ժողովրդավարություն-մաքրագիր</a></li>
              <li><a href="#text_4.2">4.2 ԿԱՌԱՎԱՐՄԱՆ ԺՈՂՈՎՐԴԱՎԱՐԱԿԱՆ ՄԵԽԱՆԻԶՄՆԵՐԸ</a></li>
              <li><a href="#text_4.3">4.3 2018թ. Թավշյա հեղափոխությունը Հայաստանում</a></li>  
              <li><a href="#text_4.4">4.4 Սոցիալական կապիտալ</a></li>
              <li><a href="#text_4.5">4.5 Քաղաքացիական հասարակությունը Հայաստանում</a></li>
              <li><a href="#text_4.6">4.6 ՔԱՂԱՔԱՑԻԱԿԱՆ ՀԱՍԱՐԱԿՈՒԹՅՈՒՆ ԵՎ ԺՈՂՈՎՐԴԱՎԱՐՈՒԹՅՈՒՆ</a></li>
              <li><a href="#text_4.7">4.7 ՍՈՑԻԱԼԱԿԱՆ ԿԱՊԻՏԱԼԸ. ԴՐԱ ՆՇԱՆԱԿՈՒԹՅՈՒՆԸ ԵՎ ԳՈՐԾԱՌՈՒՅԹՆԵՐԸ</a></li>
            </ul>
          </li>

          <li>
            <a href={'/book#bajin_5'}>ԲԱԺԻՆ 5</a>
            <ul>
              <li><a href="#text_5.1">5.1 ՔԱՂԱՔԱՑԻՈՒԹՅՈՒՆ ԵՎ ԺՈՂՈՎՐԴԱՎԱՐՈՒԹՅՈՒՆ</a></li>
              <li><a href="#text_5.2">5.2 ՔԱՂԱՔԱՑԻԱԿԱՆ ՄԱՍՆԱԿՑՈՒԹՅԱՆ ՁԵՎԵՐԸ</a></li>
              <li><a href="#text_5.3">5.3 ՎՍՏԱՀԵ՞Լ, ԹԵ՞ ՉՎՍՏԱՀԵԼ</a></li>  
              <li><a href="#text_5.4">5.4 Քաղաքացիական ինքնության զարգացումը Հայաստանում</a></li>
              <li><a href="#text_5.5">5.5 ՀԱՅԿԱԿԱՆ ՍՓՅՈՒՌՔԸ</a></li>
              <li><a href="#text_5.6">5.6 ՔԱՂԱՔԱՑԻԱԿԱՆ ՄԱՍՆԱԿՑՈՒԹՅՈՒՆԸ ՀԱՅԱՍՏԱՆՈՒՄ. ՀԱՄԱՌՈՏ ՊԱՏՄՈՒԹՅՈՒՆ</a></li>
            </ul>
          </li>

        </ul>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
       
        <Outlet/>
      </Box>
    </Box>
  );
}

export default Book;
