import map from "../img/Map.svg"
import global from "../img/global.svg"
import sms from "../img/sms.svg"
import call from "../img/call.svg"
import facebook from "../img/facebook.svg"
import "./ConnectUs.css"
import { useRef } from 'react'
import EmailJs from "./Emailjs"


function ConnectUs() {


  return (
    <div className='ConnectUs'> 
        <div className="ConnectLeft">
            <p className="connect">
                Հետադարձ կապ
            </p>
            <p className="subConnect">
                Մենք գնահատում ենք ուղիղ կապը Ձեզ հետ։ Հարցերի և առաջարկությունների դեպքում պատրաստ ենք լսել Ձեզ։ Մեր մասնագետները սիրով կաջակցեն Ձեզ։
            </p>
            <div className="sit"><img src={map} alt="" /><span className="tags">20 Marshal Baghramyan Ave, Yerevan 0019</span></div>
            <div className="sit"><img src={global} alt="" /><span className="tags"><a href="http://www.ifes.org">http://www.ifes.org</a></span></div>
            <div className="sit"><img src={sms} alt="" /><span className="tags">armenia@ifes.org</span></div>
            <div className="sit"><img src={facebook} alt="" /><span className="tags"><a href="https://www.facebook.com/IFES.ARM">https://www.facebook.com/IFES.ARM</a></span></div>
            
        </div>
        <div className="ConnectRight" >
        
          <EmailJs />


        </div>

    </div>

  );
}

export default ConnectUs;
