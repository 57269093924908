import './Header.css';
import Logo from '../img/logo.png';
import Polygon from '../img/Polygon.png';

function Header() {
  return (
    <div className="Header">
        <div className='img'>
          <img className='Logo' src={Logo} alt="" />
          
        </div>
        <div className='login'>
          
         

        </div>
    </div>
  );
}

export default Header;
