import './Learn.css';
import video from '../video/video.mp4';



function Learn() {
  return (
    <div className="Learn">
      <video className='video' controls autoPlay muted>
         <source src={video} type="video/mp4" />
      </video>
      <div className='about'>
        <p className='questions'><b>Ի՞նչ կսորվեցնի <span className='questions1'>Ներգրավված քաղաքացի</span> դասընթացը</b>
        
        </p>
        <p className='paragraph1'>
             <b>Դասընթացը նախատեսում է ուսանողներին տրամադրել  անհրաժեշտ գիտելիքներ և հմտություններ, ինչպես նաև՝ խթանել ժողովրդավարական սկզբունքները:</b>
        </p>
        <p className='paragraph1'>
             <b>Դասընթացի առաջնային նպատակներ են՝ աջակցել ուսանողներին ճանաչելու և նախապատրաստվելու այն կենսական դերակատարությունը, որը պետք է քաղաքացիները ունենան առողջ ժողովրդավարության զարգացման և պահպանման համար:</b>
        </p>
        
      </div>

    </div>
  );
}

export default Learn;
