
import './Certificate.css';
import back from '../img/Vector14.png'
import Group155 from '../img/Group155.png'
import Group1552 from '../img/Group1552.svg'
import Group1 from '../img/Group1.png'
import Group from '../img/Group.png'
import Rectangle from '../img/Certificate.svg'

function Certificate() {
  return (
    <div className="Certificate">
       <div className='certificate-main'> 
         <b><p className='why'>Ինչու՞ մասնակցել <span className='purple'>Ներգրավված քաղաքացի</span> դասընթացին </p></b> 
            <img className='group' src={Group155} alt="" />
            <img className='group2' src={Group1552} alt="" />
            
       
         <div className='black'>
           <div className='collectbig'>
           <div className='nothing'><img src={Group} alt="" /></div>
           <div>
             <p className='collect'><b>Ստացիր վկայական</b></p>
             <p className='collect1'><b>Անցնելով մեր անվճար դասընթացը՝ դուք կստանաք IFES վկայական։</b></p>
            
             </div>
         </div>
         <div className='Rectangle'>
            <img className='imgCertificate' src={Rectangle} alt="" />
         </div></div>
       </div>
    </div>

  );
}

export default Certificate;
