import './About.css';
import lines from '../img/gcer.svg'
import free from '../img/free.svg'
import one from '../img/Group131.svg'
import two from '../img/two.svg'
import tree from '../img/tree.svg'
import book2 from '../img/book2.svg'
import book3 from '../img/book3.png'
import brain from '../img/brain.svg'
import image13 from '../img/image18.svg'
import download from '../img/download.svg'
import download1 from '../img/download1.svg'
import book1 from "../books/Citizen1.pdf"
import book4 from "../books/Citizen2.pdf"
import book5 from "../books/Citizen3.pdf"
import Book from '../Book/Book';

import Link from '@mui/material/Link';


function About() {
  return (
    <div className="About">
        <p className='description'>Դասընթացի նկարագրություն <img src={lines} alt="" /></p> 
        <p>ԱՅ ԷՖ Ի ԷՍ-ի հայաստանյան մասնաճյուղը, համագործակցելով ՀՀ ԿԳՄՍՆ-ի հետ, հաջողությամբ ձևավորել է մտավորականների, դասախոսների, ինչպես նաև քաղաքացիական հասարակության ակտիվ դերակատարների մի խումբ, որոնք մշակել են քաղաքացիական կրթության մի դասընթաց՝ հիմնված տարածաշրջանում ԱՅ ԷՖ Ի ԷՍ -ի փորձի, հայկական պետականության զարգացման, ինչպես նաև՝ ժողովրդավարացման և քաղաքացիության առաջխաղացման վրա։ Դասընթացը բաղկացած է հինգ բաժնից՝ ժողովրդավարության տեսական հիմքերը, մարդու իրավունքները Հայաստանում, սահմանադրականությունը և ազատական ժողովրդավարությունը Հայաստանում, քաղաքացիական հասարակությունը Հայաստանում և քաղաքացիների ներգրավումը։ Դասընթացը բաղկացած է 3 նյութից. ուսանողի ձեռնարկ, ուսուցիչների ձեռնարկ և դասընթացի հիմնական դասագիրքը։ Դասընթացի հիմքում ընկած է ինտերակտիվ մեթոդաբանությունը,  որն ավելի հետաքրքիր և ոգեշնչող կդարձնի ուսումնական գործընթացը:</p>
        <div className='photos'>
            <div className='firstbox'>
                <div className='free'><img src={free} alt="" /></div>
                <div className='one'><img src={one} alt="" />
                   <div className='titeles'>
                      <p className='title'>Ներգրավված քաղաքացի</p>
                      <p className='uptitle'>Դասախոսի ձեռնարկ</p>
                      
                   </div> 
                    
                </div>
                <img className='picture' src={image13} alt="" />
                <a href={book1} target="_blank" ><p className='download'>Ներբեռնել PDF <img src={download} alt="" /></p></a>
            </div>
            <div className='secondbox'>
                <div className='free'><img src={free} alt="" /></div>
                <div className='one'><img src={two} alt="" />
                   <div className='titeles'>
                      <p className='title'>Ներգրավված քաղաքացի</p>
                      <p className='uptitle'> Ուսանողի ձեռնարկ</p>
                      
                   </div> 
                    
                </div>
                <img className='picture' src={book2} alt="" />
                <p className='download'>Մասնակցել հարցմանը <img className={brain} src={brain} alt="" /></p>
                <a href={book4} target="_blank"><p className='download1'>Ներբեռնել PDF <img src={download1} alt="" /></p></a>

            </div>
            <div className='thirdbox'>
                <div className='free'><img src={free} alt="" /></div>
                <div className='one'><img src={tree} alt="" />
                   <div className='titeles'>
                      <p className='title'>Ներգրավված քաղաքացի</p>
                      <p className='uptitle3'>Համալսարանական դասընթացի դասագիրք</p>
                      
                   </div> 
                    
                </div>
                <img className='picture' src={book3} alt="" />
                <a target="_blank" href="/book">
                <p className='download'>Կարդալ այստեղ <img src={download} alt="" /></p>
                </a>
                <a href={book5} target="_blank"><p className='download1'>Ներբեռնել PDF <img src={download} alt="" /></p></a>

            </div>
        </div>
    </div>

  );
}

export default About;